import React from 'react';
import { OTSession, OTPublisher, OTStreams, OTSubscriber } from 'opentok-react';

import './App.css';
import './loading.css';
import axios from 'axios';

import Stopwatch from './component/stopwatch'

const config = require('./config.json');
const Currency = require('react-currency-format');
const OT = require('@opentok/client');

export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: false,
      finished: false,
      connection: 'Connecting',
      publishVideo: true,
      publishAudio: true,
      counter: 0,
      caller: '',
      session: '',
      token: '',
      isCoach: '',
      cost: '',
      duration: '',
      myIncome: '',
      subscribed: false,
      isLoading: true,
    };

    this.endSession = this.endSession.bind(this);

    this.publisherProperties = {
      audioFallbackEnabled: false,
      showControls: false
    };

    this.sessionEventHandlers = {
      sessionConnected: () => {
        this.setState({ connection: 'Connected' });
      },
      sessionDisconnected: (data) => {
        console.log('Disconnected')
        this.setState({ connection: 'Disconnected' });
        // this.setState({
        //   connection: 'Disconnected',
        //   caller: '',
        //   session: '',
        //   token: '',
        //   isCoach: ''
        // });
      },
      sessionReconnected: () => {
        this.setState({ connection: 'Reconnected' });
      },
      sessionReconnecting: () => {
        this.setState({ connection: 'Reconnecting' });
      },
    };

    this.publisherEventHandlers = {
      accessDenied: () => {
        console.log('User denied access to media source');
      },
      streamCreated: () => {
        console.log('Publisher stream created');
      },
      streamDestroyed: ({ reason }) => {
        console.log(`Publisher stream destroyed because: ${reason}`);
      },
    };

    this.subscriberEventHandlers = {
      videoEnabled: () => {
        console.log('Subscriber video enabled');
      },
      videoDisabled: () => {
        console.log('Subscriber video disabled');
      },
    };
  }

  startSession = () => {
    let ut = getUrlParameter('ut')
    let crid = getUrlParameter('crid')
    this.setState({ isLoading: true })

    console.log('clicked start')

    axios({
      method: 'POST',
      url: `${config.url}/api/session/join`,
      headers: {
        Authorization: ut
      },
      data: {
        chatRoom: crid
      }
    }).then((data) => {
      console.log(data.data)
      if(data.data.status !== 'error') {
        let now = ~~(Date.now() / 1000)
        let fin = now - data.data.data.unixStart
        console.log(data.data.data)
        this.setState({ 
          isLoading: false,
          caller: data.data.data.interlocutors,
          session: data.data.data.session,
          token: data.data.data.token,
          isCoach: data.data.data.isCoach,
          crt: fin
        });
      } else {
        this.setState({ 
          error: data.data.message
        });
        setTimeout(async () => {
          this.setState({ 
            error: false
          });
        }, 3000);
      }
    })
  }

  endSession = () => {
    let { session, token } = this.state
    let ut = getUrlParameter('ut')
    let crid = getUrlParameter('crid')
    
    OT.initSession(config.apiKey, session).disconnect(token, (result) => {
      console.log('disco', result)
    })

    this.setState({ isLoading: true })

    setTimeout(async () => {
      let data = await axios({
        method: 'POST',
        url: `${config.url}/api/session/end`,
        headers: {
          Authorization: ut
        },
        data: {
          chatRoom: crid,
          session: session,
          timeSpent: 240
        }
      })

      console.log('end result', data.data.data)
        if(data.data.status !== 'error') {
          await this.setState({ 
            cost: data.data.data.biayaTambahan,
            duration: data.data.data.durasi,
            myIncome: data.data.data.myIncome,
            finished: true,
            caller: '',
            isLoading: false
          });
        } 
    }, 3000);
  }

  endCall = () => {
    let { session, token } = this.state
    OT.initSession(config.apiKey, session).disconnect(token, (result) => {
      console.log('disco', result)
    })

    this.setState({ 
      caller: '',
      session: '',
      token: '',
      isCoach: ''
    });
  }

  onClear = () => {
    this.setState({ 
      caller: '',
      session: '',
      token: '',
      isCoach: '',
      cost: '',
      duration: '',
      myIncome: '',
      finished: false
    });
  }

  onSessionError = error => {
    if(error.code === 1004) {
      console.log(error.message)
      this.setState({ error: true })
    } else {
      console.log('pesan error', error.message)
    }
  };

  onPublish = () => {
    console.log('Publish Success');
  };

  onPublishError = error => {
    this.setState({ error });
  };

  onSubscribe = () => {
    this.setState({ subscribed: true })
    console.log('Subscribe Success');
  };

  onSubscribeError = error => {
    this.setState({ error });
  };

  toggleVideo = () => {
    this.setState(state => ({
      publishVideo: !state.publishVideo,
    }));
  };

  toggleAudio = () => {
    this.setState(state => ({
      publishAudio: !state.publishAudio,
    }));
  };

  render() {
    const { error, caller, publishVideo, isCoach, publishAudio, session, token, finished, cost, myIncome, duration, crt, subscribed, isLoading } = this.state;

    return (
      <div style={{ width: '100%'}}>
        <div className="caller">
        </div>
        <div className="callerNameContainer">
          <div className="callerName">
            {error ?  "GorryWell": session? caller : null} 
          </div>
        </div>
        {error ?(
        <div className="errorContainer">
          <strong>Oops !</strong> {error}
        </div>
        ):null}
        {isLoading? (
        <div className="isLoading">
          <div className="sp sp-slices" style={{ margin: '10px auto'}} />Mohon Menunggu
        </div>
        ): null}
        {error ? (
          <div style={{ position: 'absolute', width: '100%', zIndex: 9995 }}>
            <div className="error" style={{ width: '60%', backgroundColor: 'white',  margin: '20% auto', borderRadius: 8}}>
              <div style={{ textAlign: 'center', paddingTop: '10%' }}>
                <div style={{ fontSize: 26 }}>Sesi Selesai</div>
                <div style={{ width: 350, margin: '0px auto', paddingLeft: 40, paddingRight: 40, paddingTop: 25 }}>Silakan kembali ke aplikasi Gorrywell yang ada pada perangkat anda</div>
                <div style={{ width: 100, margin: '25px auto auto auto',position: 'relative', cursor: 'pointer', padding: '10px 45px 10px 45px', borderRadius: 8, fontWeight: 'bold',backgroundColor: '#3db55b', color: 'white'}}>Mengerti</div>
              </div>
            </div>
          </div>
        ) : null}

        {finished ? (
          <div style={{ position: 'absolute', width: '100%', zIndex: 9995 }}>
            <div className="error" style={{ width: '60%', backgroundColor: 'white',  margin: '20% auto', borderRadius: 8, zIndex: 9995}}>
              <div style={{ textAlign: 'center', paddingTop: '10%', paddingBottom: '10%' }}>
                <div style={{ fontSize: 26 }}>Video Call Bill</div>
                <div style={{ width: 350, margin: '0px auto', paddingLeft: 40, paddingRight: 40, paddingTop: 25 }}>
                  <table>
                    <tr>
                      <td style={{ width: 120, color: 'grey', textAlign: 'left'}}>Durasi</td>
                      <td style={{ color: 'grey'}}>:</td>
                      <td style={{ width: 180, textAlign: 'right' }}>{duration} Menit</td>
                    </tr>
                    <tr>
                      <td style={{ width: 120, color: 'grey', textAlign: 'left'}}>Biaya Tambahan</td>
                      <td style={{ color: 'grey'}}>:</td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold' }}>- <Currency value={cost} displayType={'text'} thousandSeparator={true} prefix={'Rp '} /></td>
                    </tr>
                    <tr>
                      <td style={{ width: 120, color: 'grey', textAlign: 'left'}}>My Income</td>
                      <td style={{ color: 'grey'}}>:</td>
                      <td style={{ textAlign: 'right', fontWeight: 'bold', color: '#3db55b' }}><Currency value={myIncome} displayType={'text'} thousandSeparator={true} prefix={'Rp '} /></td>
                    </tr>
                  </table>
                </div>
                <div onClick={this.onClear} style={{ width: 100, margin: '25px auto auto auto',position: 'relative', cursor: 'pointer', padding: '10px 45px 10px 45px', borderRadius: 8, fontWeight: 'bold',backgroundColor: '#3db55b', color: 'white'}}>Mengerti</div>
              </div>
            </div>
          </div>
        ) : null }

        {session ? (
        <OTSession
          apiKey={config.apiKey}
          sessionId={session}
          token={token}
          onError={this.onSessionError}
          eventHandlers={this.sessionEventHandlers}
        >
          <div className="subscriberLayer">
            <OTPublisher
              properties={{
                publishVideo, 
                publishAudio,
                width: 250, 
                height: 170, 
                audioFallbackEnabled: false,
                showControls: false 
              }}
              onPublish={this.onPublish}
              onError={this.onPublishError}
              eventHandlers={this.publisherEventHandlers}
            />
          </div>
          <OTStreams>
            <div className="streamLayer" style={{ width:window.innerHeight * 0.65, margin: '0 auto', backgroundColor: '#888' }}>
            <OTSubscriber
              properties={{
                width: window.innerHeight * 0.65, 
                // width: '100%', 
                height: window.innerHeight, 
                audioFallbackEnabled: false,
                showControls: false 
              }}
              onSubscribe={this.onSubscribe}
              onError={this.onSubscribeError}
              eventHandlers={this.subscriberEventHandlers}
            />
            </div>
          </OTStreams>
          </OTSession>
          ): (
            <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'white', zIndex: 9999}}>
              <div style={{ width: 380, margin: '80px auto', textAlign: 'center' }}>
                <img src="logo.png" alt="GorryWell" />
              </div>
              <div style={{ width: 380, margin: '120px auto', textAlign: 'center'}}>  
                <h1>Pindah Perangkat</h1>
                <p style={{ marginBottom: 40 }}>Anda sedang terhubung dengan perangkat lain, ingin melanjutkan di perangkat ini?</p>
                <span className="btContinue" onClick={this.startSession}> Lanjutkan </span>
              </div>
            </div>
          )}
          {error ? null: session ? !finished ? (
          <div className="videoNavigation">
            {subscribed? null: (
              <div style={{ color: 'white', fontSize: 16, margin: '15px auto', padding: '2px 20px', backgroundColor: 'rgb(86 83 83)', width: 118, borderRadius: 4 }}>Menunggu Klien</div>
            )}
            <Stopwatch time={crt} />
            <div className="buttonContainer">
              {publishVideo ? 
                <img src="CameraWeb.png" className="vCbutton" onClick={this.toggleVideo} alt="Enable Camera" /> :
                <img src="DisableCameraWeb.png" className="vCbutton" onClick={this.toggleVideo} alt="Disable Camera" /> 
              }

              {isCoach?
                <img src="EndCallWeb.png" className="eCbutton" onClick={this.endSession} alt="End Call" />:
                <img src="EndCallWeb.png" className="eCbutton" onClick={this.endCall} alt="End Call" />
              }

              {publishAudio ? 
                <img src="UnmuteMicWeb.png" className="vVbutton" onClick={this.toggleAudio} alt="Disable Audio" /> : 
                <img src="MicWeb.png" className="vVbutton" onClick={this.toggleAudio} alt="Enable Audio" />
              }
            </div>
          </div>
          ): null: null}
      </div>
    );
  }
}

function getUrlParameter(name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(window.location.search);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
