import React, { useState, useRef, useEffect } from 'react';
import './../App.css';
const Stopwatch = (data) => {
  const [timer, setTimer] = useState(data.time)
  const increment = useRef(null)

  const handleStart = () => {
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1)
    }, 1000)
  }

  const formatTime = () => {
    const getSeconds = `0${(timer % 60)}`.slice(-2)
    const minutes = `${Math.floor(timer / 60)}`
    const getMinutes = `0${minutes % 60}`.slice(-2)
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2)

    return (
        <div style={{textAlign: 'center', zIndex: 9995, marginBottom: 20}}>
            <div style={{fontSize: '16px', color: 'white', fontWeight: 'bold'}}>
            <span>{getHours}</span>:<span>{getMinutes}</span>:<span>{getSeconds}</span>
            </div>
        </div>
    );
  }

  useEffect(() => {
    handleStart(true);
}, []);

  return (
    <div className="app">
      <div className='stopwatch-card'>
        <p>{formatTime()}</p>
      </div>
    </div>
  );
}

export default Stopwatch;